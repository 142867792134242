/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import Img from 'gatsby-image'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { getMappable } from '_utils/get-mappable'
import { useWindowSize } from '_hooks'
import { AppContext } from '_context'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { Container, Image, Text, ScrollArrow, Heading } from '_atoms'
import { ProudCases, NavLink } from '_molecules'
import SodexoLogo from '_images/pages/portfolio/sodexo/sodexo-logo.inline.png'
import WizeoLogo from '_images/pages/portfolio/sodexo/wizeo-logo.inline.png'
import AppStoreBadge from '_images/svgs/badge-app-store.svg'
import PlayStoreBadge from '_images/svgs/badge-play-store.svg'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const SodexoCaseStudy = ({ data }) => {
  const content = data.contentfulSodexoCaseStudy

  const [width] = useWindowSize()
  const isMobile = width <= 768

  const { setNavbarColor, setNavbarLight, setCustomBrandColor } = useContext(AppContext)

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor('transparent')
    setNavbarLight(false)
    setCustomBrandColor('white')
  }, [setNavbarColor, setNavbarLight, setCustomBrandColor])

  return (
    <Layout navbarColorOverride="transparent">
      <SEO
        title={content?.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          content?.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={content.pageThumbnail.file.url}
      />

      <section className={styles.hero}>
        <Container>
          <Row className={styles.heroRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.heroContent}>
              <Fade delay={200} distance="20%" bottom>
                <Row className={styles.heroCompanyLogos}>
                  <Image src={SodexoLogo} alt="sodexo-logo" />
                  <Image src={WizeoLogo} alt="wizeo-logo" />
                </Row>
              </Fade>
              <div className={styles.heroText}>
                <Fade delay={250} distance="20%" bottom>
                  <Text size="24" className={styles.heroDescription} splitParagraphs>
                    {content.heroDescription.internal.content}
                  </Text>
                </Fade>
              </div>
              <Fade delay={300} distance="20%" bottom>
                <Row className={styles.heroStoreLogos}>
                  <NavLink hasPrefix={false} to="https://apps.apple.com/br/app/wizeo/id1298703833">
                    <AppStoreBadge />
                  </NavLink>
                  <NavLink
                    to="https://play.google.com/store/apps/details?id=com.laagenciadigital.wizeo"
                    hasPrefix={false}
                  >
                    <PlayStoreBadge />
                  </NavLink>
                </Row>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.heroImageWrapper}>
              <Fade distance="20%" delay={350} bottom>
                <Img
                  fluid={content.heroImage.fluid}
                  alt={content.heroImage.description}
                  objectFit="contain"
                  imgStyle={{ objectFit: 'contain' }}
                />
              </Fade>
            </Col>
          </Row>
          <Row className={styles.heroArrowWrapper}>
            <Fade distance="20%" delay={400} bottom>
              <ScrollArrow className={styles.heroArrowButton} isButton scrollId="product" />
            </Fade>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor('transparent')
            setNavbarLight(false)
            setCustomBrandColor('white')
          }}
        />
      </section>

      <section
        className={styles.product}
        style={{
          backgroundImage: `url(${
            isMobile
              ? content.productBackgroundImageMobile.file.url
              : content.productBackgroundImage.file.url
          })`,
        }}
        id="product"
      >
        <Container>
          <Row className={styles.productRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" delay={200} bottom>
                <Heading
                  type="h1"
                  bold
                  color="white"
                  className={classNames(styles.heading, styles.headingLineBreak)}
                >
                  {content.productTitle}
                  <Text color="sodexoSecondary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  size="22"
                  className={classNames(styles.description, styles.productDescription)}
                  color="dark"
                  splitParagraphs
                >
                  {content.productDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.productImageWrapper}>
              <Fade distance="10%" delay={400} bottom>
                <Img
                  fluid={content.productImage.fluid}
                  alt={content.productImage.description}
                  fadeIn={false}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor('transparent')
            setNavbarLight(false)
            setCustomBrandColor('white')
          }}
        />
      </section>

      <section className={styles.challenge}>
        <Container>
          <Row className={styles.challengeRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.challengeTextsWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading type="h4" bold color="sodexoSecondary" className={styles.heading}>
                  {content.challengeTitle}
                  <Text className={styles.dot}>.</Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  size="22"
                  className={classNames(styles.description, styles.challengeDescription)}
                  color="dark"
                  splitParagraphs
                >
                  {content.challengeDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.challengeImageWrapper}>
              <Fade distance="10%" delay={400} bottom>
                <Img
                  fluid={content.challengeImage.fluid}
                  alt={content.challengeImage.description}
                  fadeIn={false}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
            setCustomBrandColor('dark')
          }}
        />
      </section>

      <section className={styles.features}>
        <Container>
          <Row className={styles.featuresRow}>
            <Col xs={12} sm={12} md={12} lg={12} className={styles.featuresRowTexts}>
              <Fade distance="10%" delay={200} bottom>
                <Heading type="h4" bold color="white" className={styles.heading}>
                  {content.featuresTitle}
                  <Text color="sodexoSecondary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  className={classNames(styles.description, styles.featuresDescription)}
                  color="white"
                  splitParagraphs
                  size="22"
                >
                  {content.featuresDescription.internal.content}
                </Text>
              </Fade>
              <div className={styles.featuresImageWrapper}>
                <Fade distance="10%" delay={400} bottom>
                  <Img
                    fluid={content.featuresImage.fluid}
                    alt={content.featuresImage.description}
                    fadeIn={false}
                  />
                </Fade>
              </div>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['sodexo-blue-dark'])
            setNavbarLight(false)
            setCustomBrandColor('white')
          }}
        />
      </section>

      <section className={styles.map}>
        <Container>
          <Row className={styles.mapRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.mapTextsWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading type="h4" bold color="sodexoSecondary" className={styles.heading}>
                  {content.mapTitle}
                  <Text className={styles.dot}>.</Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  size="22"
                  className={classNames(styles.description, styles.mapDescription)}
                  color="dark"
                  splitParagraphs
                >
                  {content.mapDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.mapImageWrapper}>
              <Fade distance="10%" delay={400} bottom>
                <Img
                  fluid={content.mapImage.fluid}
                  alt={content.mapImage.description}
                  fadeIn={false}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
            setCustomBrandColor('dark')
          }}
        />
      </section>

      <section className={styles.route}>
        <Container>
          <Row className={styles.routeRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.routeTextsWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading type="h4" bold color="white" className={styles.heading}>
                  {content.routeTitle}
                  <Text className={styles.dot}>.</Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text
                  size="22"
                  className={classNames(styles.description, styles.routeDescription)}
                  color="white"
                  splitParagraphs
                >
                  {content.routeDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.routeImageWrapper}>
              <Fade distance="10%" delay={400} bottom>
                <Img
                  fluid={content.routeImage.fluid}
                  alt={content.routeImage.description}
                  fadeIn={false}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor('transparent')
            setNavbarLight(false)
            setCustomBrandColor('white')
          }}
        />
      </section>

      <section className={styles.placeholder} />

      <section className={styles.results}>
        <div className={styles.resultsBg}>
          <Container className={styles.resultsContentWrapper}>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
                <div
                  className={classNames(
                    styles.resultsTextWrapper,
                    styles.resultsHeadingTextWrapper
                  )}
                >
                  <Fade distance="10%" delay={200} bottom>
                    <Heading type="h4" bold color="white" className={styles.resultsHeading}>
                      <FormattedMessage id="casesCommon.results" />
                      <Text color="white" className={styles.dot}>
                        .
                      </Text>
                    </Heading>
                  </Fade>
                </div>
              </Col>
              {getMappable('results', 1, content).map((result, index) => (
                <Col
                  xs={12}
                  sm={8}
                  md={8}
                  lg={8}
                  className={styles.resultsColumnWrapper}
                  key={result.title}
                >
                  <Fade distance="10%" delay={(index + 2) * 200} bottom>
                    <div className={styles.resultsContainer}>
                      <Image src={result.image.file.url} alt={result.image.description} />
                      <div>
                        <Heading type="h5" bold color="white">
                          {result.title}
                        </Heading>
                        <Text size="18" color="white">
                          {result.description.internal.content}
                        </Text>
                      </div>
                    </div>
                  </Fade>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-dark'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.placeholder} />

      <section className={styles.portfolio}>
        <Fade distance="10%" bottom>
          <ProudCases currentProject="Sodexo" caseStudies={content.proudCases} extendedCTA />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query contentfulSodexoCaseStudy($locale: String) {
    contentfulSodexoCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        description
        file {
          url
        }
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      productTitle
      productDescription {
        internal {
          content
        }
      }
      productImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      productBackgroundImage {
        description
        file {
          url
        }
      }
      productBackgroundImageMobile {
        description
        file {
          url
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      challengeImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featuresTitle
      featuresDescription {
        internal {
          content
        }
      }
      featuresImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mapTitle
      mapDescription {
        internal {
          content
        }
      }
      mapImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      routeTitle
      routeDescription {
        internal {
          content
        }
      }
      routeImage {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      resultsImage1 {
        description
        file {
          url
        }
      }
      resultsTitle1
      resultsDescription1 {
        internal {
          content
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

SodexoCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default SodexoCaseStudy
